import MovieBollywood from "../Components/MovieBollywood";
import { BollywoodMovieData } from "../data/BollywoodMovieData";
import styled from "styled-components";

const Bollywood = () => {
  return (
    <BollywoodContainer>
      <h2>Bollywood Movie</h2>
      <div className="container">
        {BollywoodMovieData.map((Id, Index) => (
          <MovieBollywood items={Id} key={Index} />
        ))}
      </div>
    </BollywoodContainer>
  );
};

const BollywoodContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  .container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
`;

export default Bollywood;
