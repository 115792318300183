import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import CardTv from "../Components/CardTv";

const TvList = () => {
  const [movieList, setMovieList] = useState([]);
  const [pageNumber, setPageNumber] = useState(2)
  const [totalPage, setTotalPage] = useState()
  const { type } = useParams();

  useEffect(() => {
    getData();
  }, [type]);


  const getData = async () => {
    try {
      const url = `https://api.themoviedb.org/3/tv/${type ? type : "popular"}?api_key=4e44d9029b1270a757cddc766a1bcb63&language=en-US&page=1`;
      const res = await fetch(url);
      const data = await res.json();
      setMovieList(data.results);
      setTotalPage(data.total_pages)

    } catch (e) {
      console.log(e);
    }
  };
  const nextGetData = async () => {
    const url = `https://api.themoviedb.org/3/tv/${type ? type : "popular"}?api_key=4e44d9029b1270a757cddc766a1bcb63&language=en-US&page=${pageNumber}`;
    setPageNumber(pageNumber+1) 
    const res = await fetch(url);
    const data = await res.json();
    setMovieList(movieList.concat(data.results));
  };


  // const increment = ()=>{
  //   setPageNumber((prev)=>prev+1)
  // }
  // const decrement = ()=>{
  //   setPageNumber((prev)=>prev-1)
  // }


  return (<>

    <InfiniteScroll
      dataLength={movieList.length} //This is important field to render the next data
      next={nextGetData}
      hasMore={pageNumber <= totalPage}
      loader={<h4>Loading...</h4>}
    >

      <MovieListContainer>
        <div>
          <h2>{(type ? type : "Popular").toUpperCase()}</h2>
        </div>
        <div className="movieList">
          {movieList.map((movie, Index) => (
            <CardTv movie={movie}  key={Index}/>
          ))}
        </div>
        
      </MovieListContainer>

    </InfiniteScroll>
  </>
  );
};

const MovieListContainer = styled.div`
.movieList{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

`;
export default TvList;
