import "./App.css";
import Navbar from "./Components/Navbar";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import MovieList from "./Pages/MovieList";
import Movie from "./Pages/Movie";
import DownloadMovie from "./Pages/DownloadMovie";
import Search from "./Pages/Search";
import TvHome from "./Pages/TvHome";
import SearchTv from "./Pages/SearchTv";
import Bollywood from "./Pages/Bollywood";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route  index element={<Home/>} />
          <Route path="/movie/:id" element={<Movie/>} />
          <Route path="/Bollywood/movies" element={<Bollywood/>} />
          <Route path="/:tv/:id" element={<Movie/>} />
          <Route path="/movie/:id/download" element={<DownloadMovie/>} />
          <Route path="/:tv/:id/download" element={<DownloadMovie/>} />
          <Route path="/movies/:type" element={<MovieList/>} />
          <Route path="/webseries" element={<TvHome/>} />
          <Route path="/movies/search" element={<Search/>} />
          <Route path="/Webseris/search" element={<SearchTv/>} />
          <Route path="/*" element={<h1>Error</h1>} />
        </Routes>

      </Router>
    </>
  );
}

export default App;
