import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { MovieData } from "../data/MovieDownloadData";


const Movie = () => {
  const [movieDetail, setMovieDetail] = useState([]);
  const { id, tv } = useParams();
  const downloadId = id;
  const serachDownladLink = MovieData.filter((item) => {
    return item.id == `${downloadId}`;
  });

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    fetch(
      `https://api.themoviedb.org/3/${tv ? tv : "movie"
      }/${id}?api_key=4e44d9029b1270a757cddc766a1bcb63&language=en-US`
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        setMovieDetail(data);
      });
  }

  return (
    <MoviveContainer>
      <div className="movieContainer">
        {
          serachDownladLink[0] ?
            <>{serachDownladLink[0].headText ? (
              <h1 className="headText1">{serachDownladLink[0].headText}</h1>
            ) : (
              ''
            )}
            </> : <h1>{movieDetail.title}</h1>
        }
        {/* <div className="movie-img">
          <img
            src={`https://image.tmdb.org/t/p/original/${movieDetail.backdrop_path}`}
            alt=""
          />
        </div> */}
        <div className="info-box">
          <div className="box-img-side">
              <img onClick={()=>{
                navigator.clipboard.writeText(`https://image.tmdb.org/t/p/w500/${movieDetail.poster_path}`)
              }}
              src={`https://image.tmdb.org/t/p/w500/${movieDetail.poster_path}`}
              alt=""
            />
          </div>
          <div className="box-text-side">
            {/* {
              movieDetail.title?<h1>{movieDetail.title}</h1>:''
            } */}
            {
              movieDetail.tagline ? <div className="p">{movieDetail.tagline}</div> : ''
            }
            {
              movieDetail.vote_average ? <div className="details-of-rating">
                <span onClick={()=>{
              navigator.clipboard.writeText(`${movieDetail.vote_average}`)
            }}>
                  {movieDetail.vote_average}
                  <i class="fa-solid fa-star"></i>
                </span>
                <span>({movieDetail.vote_count}) vote</span>
              </div> : ''
            }
            {
              movieDetail.release_date ? <div className="p">Release date : <span onClick={()=>{
                navigator.clipboard.writeText(`${movieDetail.release_date}`)
              }}>{movieDetail.release_date}</span> </div> : ""
            }

            <div className="genres"  >
              {movieDetail.genres
                ? movieDetail.genres.map((genre) => (
                  <> |
                    <span className="genre" id={genre.id}>
                      {genre.name } 
                    </span>
                  </>
                ))
                : ""}
            </div>
            {
              movieDetail.overview ? <div className="sypnosis">
                <h1>sypnosis</h1>
                <div className="p" onClick={()=>{
              navigator.clipboard.writeText(`${movieDetail.overview}`)
            }}>{movieDetail.overview}</div>
              </div> : ''
            }

          </div>
        </div>
      </div>
      {/* 
      <div className="download">
        <Link className="btn" to={`/${tv ? tv : "movie"}/${id}/download`}>
          Download
        </Link>
      </div> */}
    </MoviveContainer>
  );
};

const MoviveContainer = styled.div`
width: 80%;
.headText1{
  width: 100%;
  text-align: center;
  padding: 0 10px;
  font-weight: 900;
    font-size: 18px;
    word-wrap: break-word!important;
}
  .movieContainer {
    width: 100%;
    height: auto;
    position: relative;
    .movie-img {
      z-index: -2;
      img {
        width: 100%;
        height: 750px;
      }
    }
    .movie-img::before {
      content: "";
      /* position: absolute; */
      width: 100%;
      height: 800px;
      background-color: black;
      z-index: 99;
      opacity: 0.3;
    }
    .info-box {
      /* position: absolute; */
      /* margin-top: 40%; */
      left: 0;
      bottom: -20%;
      left: 6%;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .box-img-side {
        img {
          width: 250px;
          border: 2px solid white;
          border-radius: 10px;
        }
      }
      .details-of-rating {
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .genres {
        flex-wrap: wrap;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 0 !important;

        .genre {
          margin-top: 10px;
          border: 2px solid white;
          padding: 7px 14px;
          border-radius: 10px;
      font-size: 14px !important;
        }
      }
      .box-text-side {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-left: 20px;
        h1 {
          line-height: 0;
        }
        .p {
          font-weight: 500;
        }
      }
      .sypnosis {
        /* margin-top: 70px; */
      }
    }
  }
  .download {
    margin-top: 10%;
    margin: 15% 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .btn {
      all: unset;
      font-size: 40px;
      font-weight: 900;
      border: 2px solid white;
      border-radius: 10px;
      padding: 11px 24px;
      transition: 0.7s;
    }
    .btn:hover {
      scale: 0.9;
    }
  }

  @media only screen and (max-width: 450px) {
    width: 100%;
    .movie-img {
      width: 80% !important;
      opacity: 0;
      position: absolute;
    }
  

    .info-box {
      width: 100% !important;
      overflow-x: hidden !important;
      position: relative !important;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 10% !important;
      text-align: center;
      left: 0 !important;
    }
    .box-img-side{
      width: 70%;
    }
    h1 {
      font-size: 25px;
      line-height: 1 !important;
    }
    .box-text-side{
      margin-left: 0 !important;
      padding: 0 5px;
      align-items: center;
     
    }
    .sypnosis {
        .p{
          font-size: 12px !important;
        }
      }
      .genres{
        gap: 10px !important;
        margin-bottom: 0 !important;
      }
      .genre{
        font-size: 16px !important;
      }
  }
`;

export default Movie;
