import React, { useEffect } from 'react'
import { useState } from 'react';
import Card from './Card';
import styled from "styled-components";

const MovieBollywood = ({items}) => {
  const [data, setData] = useState([])
    useEffect(()=>{
      fetch(
        `https://api.themoviedb.org/3/movie/${items}?api_key=4e44d9029b1270a757cddc766a1bcb63&language=en-US`
      )
        .then((res) => res.json())
        .then((data) => {
            // console.log(data);
            setData(data)
        });
    },[items])

  return (
    <MovieBollywoodContainer>
        <Card movie={data}/>
    </MovieBollywoodContainer>
  )
}

const MovieBollywoodContainer = styled.div `




`

export default MovieBollywood
