import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TvList from "./TvList";


const TvHome = () => {
  const [PopularMovie, setPopularMovie] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.themoviedb.org/3/tv/popular?api_key=4e44d9029b1270a757cddc766a1bcb63&language=en-US&page=1"
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.results);
        setPopularMovie(data.results);
      });
  }, []);

  return (
    <>
      <Carousel
        showThumbs={false}
        autoPlay={true}
        transitionTime={3}
        infiniteLoop={true}
        showStatus={false}
      >
        {PopularMovie.map((movie, Index) => ( 
          <Link className="Link-Remove" target="_blank"  to={`/tv/${movie.id}/download` }>
            <CarouselCard  key={Index}>
              <div className="backdrop-img">
                <img
                  src={`https://image.tmdb.org/t/p/original/${movie.backdrop_path}`}
                  alt=""
                />
              </div>
              <div className="text-info">
                <h1 key={Index}>{movie.name}</h1>
                <p>{movie.release_date}
                <span>{movie.vote_average}<i class="fa-solid fa-star"></i></span>
                </p>
                <p>
                  {movie.overview.slice(0,120)}.... 
                </p>
              </div>
            </CarouselCard>
          </Link>
        ))}
      </Carousel>
      <TvList/>
    </>
  );
};

const CarouselCard = styled.div`
  width: 100%;
  height: 550px;
  position: relative;
  .backdrop-img {
    position: relative;
    z-index: 97;
    img {
      width: 100%;
      z-index: 8;
    }
  }
  .backdrop-img::before {
    position: absolute;
    content: "";
    background-color: black;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
  }
  .text-info {
    margin-left: 20px;
    position: absolute;
    z-index: 99;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height:0;
    text-align: left;
    max-width: 40%;
    h1 {
      font-size: 30px;
      text-shadow: 3px 0px 7px rgba(81,67,21,0.8), -3px 0px 7px rgba(81,67,21,0.8), 0px 4px 7px rgba(81,67,21,0.8);;
    }
    p{
      display: flex;
      gap: 10px;
      line-height: 0.9;
      text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
    }
    span{
      display: flex;
      gap: 2px;
      text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
    }
  }
  @media only screen and (max-width: 425px) {
    height: 50%;
    font-size: 12px;
    .text-info {
    margin-left: 20px;
    position: absolute;
    z-index: 99;
    top  :100%;
    bottom: 0 !important;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    line-height:0;
    text-align: left;
    max-width: 100%;
    height: 100%;
    h1 {
   
      font-size: 12px;
      text-shadow: 3px 0px 7px rgba(81,67,21,0.8), -3px 0px 7px rgba(81,67,21,0.8), 0px 4px 7px rgba(81,67,21,0.8);;
    }
    p{
      font-size: 12px;
      display: flex;
      gap: 10px;
      line-height: 0.9;
      text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
    }
    span{
      display: flex;
      gap: 2px;
      text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
    }
  }

  }
`;

export default TvHome;
