import React, { useState } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import CardTv from "../Components/CardTv";

const SearchTv = () => {
  const [movieList, setMovieList] = useState([]);
  const [message, setMessage] = useState("");
  const [searched, setSearched] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const searchMovie = async (e) => {
    e.preventDefault();
    // console.log("Searching");
    setSearched(true);
    try {
      const url = `https://api.themoviedb.org/3/search/tv?api_key=bcc4ff10c2939665232d75d8bf0ec093&query=${message}&page=${pageNumber}`;
      const res = await fetch(url);
      const data = await res.json();
      setMovieList(data.results);
      setTotalPage(data.total_pages);
    } catch (e) {
      console.log(e);
    }
  };

  const nextGetData = async () => {
    setPageNumber(pageNumber + 1);
    const url = `https://api.themoviedb.org/3/search/tv?api_key=bcc4ff10c2939665232d75d8bf0ec093&query=${message}&page=${pageNumber}`;
    const res = await fetch(url);
    const data = await res.json();
    setMovieList(movieList.concat(data.results));
  };

  return (
    <SearchContainer>
      <form onSubmit={searchMovie}>
        <input
          type="text"
          id="message"
          name="message"
          onChange={handleChange}
          value={message}
          placeholder="search a Web series"
        />
        <button type="submit">
          <i class="fas fa-search"></i>
        </button>
      </form>
      {searched ? (
        <>
          <Link to="/movies/search" className="SearchWebseries">Click here to Search Movie</Link>
          <InfiniteScroll
            dataLength={movieList.length} //This is important field to render the next data
            next={nextGetData}
            hasMore={pageNumber <= totalPage}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div>
              <h2>{searched ? `Searched : ${message}` : ``}</h2>
            </div>
            <div className="movieListFlex">
              {movieList.map((movie, Index) => (
                <CardTv movie={movie} key={Index} />
              ))}
            </div>
          </InfiniteScroll>
        </>
      ) : (
        <>
          <Link to="/movies/search" className="SearchWebseries">Click here to Search Movie</Link>
          <h1>please search Web Series </h1>
        </>
      )}
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  /* margin: 20px auto; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 77px;
  }
  input {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-weight: 600;
    padding-left: 6px;
    font-size: 17px;
    width: 60%;
    height: 40px;
  }

  button {
    color: white;
    background-color: transparent;
    border: none;
    font-size: 20px;
  }

  .movieListFlex {
  padding: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: auto;
    flex-wrap: wrap;
    overflow: hidden;
  }

  .SearchWebseries{
    color: red !important;
    font-weight: 900;
    font-size: 16px;
    text-decoration: underline;
    color: blue;
  }
  @media only screen and (max-width: 425px) {
    h1{
      font-size: 20px;
    }
  }
`;

export default SearchTv;
