import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { MovieData } from "../data/MovieDownloadData";
import Movie from "./Movie";
import Series from "./Series";

const DownloadMovie = () => {
  const [movieDetail, setMovieDetail] = useState([]);
  const { id, tv } = useParams();
  const downloadId = id;

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    fetch(
      `https://api.themoviedb.org/3/${tv ? tv : "movie"
      }/${id}?api_key=4e44d9029b1270a757cddc766a1bcb63&language=en-US`
    )
      .then((res) => res.json())
      .then((data) => {
        setMovieDetail(data);
      });
  };

  const serachDownladLink = MovieData.filter((item) => {
    return item.id == `${downloadId}`;
  });
  return (
    <DownloadMovieContainer>
      {tv ? <Series /> : <Movie />}

      {serachDownladLink[0] ? (
        <>
          {serachDownladLink[0].headText ? (
            <h1 className="headText">{serachDownladLink[0].headText}</h1>
          ) : (
            ""
          )}
          {serachDownladLink[0].metaDescription ? (
            <p>{serachDownladLink[0].metaDescription}</p>
          ) : (
            ""
          )}

          {movieDetail.backdrop_path ? (
            <img onClick={()=>{
              navigator.clipboard.writeText(`https://image.tmdb.org/t/p/original${movieDetail.backdrop_path}`)
            }}
              src={`https://image.tmdb.org/t/p/original${movieDetail.backdrop_path}`}
              alt=""
            /> 
          ) : (
            ""
          )}

          {movieDetail.overview ? (
            <p className="overview">{movieDetail.overview}</p>
          ) : (
            ""
          )}
          {serachDownladLink[0].original_title ? (
            <h1>
              Download
              <span className="original_title">
                {" "}
                {serachDownladLink[0].original_title}{" "}
              </span>
              720p & 1080p & 2k Web-DL Dual Audio
            </h1>
          ) : (
            ""
          )}

          {/* <div className="genres">
            {movieDetail.genres
              ? movieDetail.genres.map((genre) => (
                <>
                  <span className="genre" id={genre.id}>
                    {genre.name}
                  </span>
                </>
              ))
              : ""}
          </div> */}

          {/* images  */}
          {serachDownladLink[0].img1 ? (
            <>
              <h3>-- ScreenShot --</h3>
              <div className="imgbox">
                {serachDownladLink[0].img1 ? (
                  <img
                    src={serachDownladLink[0].img1}
                    alt={serachDownladLink[0].original_title}
                  />
                ) : (
                  ""
                )}

                {serachDownladLink[0].img2 ? (
                  <img
                    src={serachDownladLink[0].img2}
                    alt={serachDownladLink[0].original_title}
                  />
                ) : (
                  ""
                )}

                {serachDownladLink[0].img3 ? (
                  <img
                    src={serachDownladLink[0].img3}
                    alt={serachDownladLink[0].original_title}
                  />
                ) : (
                  ""
                )}

                {serachDownladLink[0].img4 ? (
                  <img
                    src={serachDownladLink[0].img4}
                    alt={serachDownladLink[0].original_title}
                  />
                ) : (
                  ""
                )}

                {serachDownladLink[0].img5 ? (
                  <img
                    src={serachDownladLink[0].img5}
                    alt={serachDownladLink[0].original_title}
                  />
                ) : (
                  ""
                )}

                {serachDownladLink[0].img6 ? (
                  <img
                    src={serachDownladLink[0].img6}
                    alt={serachDownladLink[0].original_title}
                  />
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            ""
          )}

          {/* frist*/}
          <div className="linkBox">
            <hr width="100%" color="white" />
            {serachDownladLink[0].fristLinkText ? (
              <h1>{serachDownladLink[0].fristLinkText}</h1>
            ) : (
              ""
            )}
            {serachDownladLink[0].fristSize ? (
              <div className="h4">{serachDownladLink[0].fristSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].fristLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].fristLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            {/* // episode link will be here */}

            <div className="EpisodeBtn">
              {serachDownladLink[0].fristEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fristBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fristBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* second */}
          <div className="linkBox">
            {serachDownladLink[0].SecondLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].SecondLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].SecondSize ? (
              <div className="h4">{serachDownladLink[0].SecondSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].SecondLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].SecondLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}
            <div className="EpisodeBtn">
              {serachDownladLink[0].secondEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].secondBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].secondBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* third */}
          <div className="linkBox">
            {serachDownladLink[0].ThirdLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].ThirdLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].ThirdSize ? (
              <div className="h4">{serachDownladLink[0].ThirdSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].ThirdLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].ThirdLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}
            <div className="EpisodeBtn">
              {serachDownladLink[0].thirdEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].thirdBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].thirdBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* Four */}
          <div className="linkBox">
            {serachDownladLink[0].FourLinkText ? (
              <>
                <hr width="100%" color="white" />

                <h1>{serachDownladLink[0].FourLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].FourSize ? (
              <div className="h4">{serachDownladLink[0].FourSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].FourLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].FourLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            <div className="EpisodeBtn">
              {serachDownladLink[0].fourEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fourBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fourBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* Five  */}
          <div className="linkBox">
            {serachDownladLink[0].FiveLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].FiveLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].FiveSize ? (
              <div className="h4">{serachDownladLink[0].FiveSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].FiveLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].FiveLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            <div className="EpisodeBtn">
              {serachDownladLink[0].fiveEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].fiveBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].fiveBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* six */}
          <div className="linkBox">
            {serachDownladLink[0].sixLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].sixLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].sixSize ? (
              <div className="h4">{serachDownladLink[0].sixSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].sixLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].sixLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            <div className="EpisodeBtn">
              {serachDownladLink[0].sixEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sixBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sixBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* seven */}
          <div className="linkBox">
            {serachDownladLink[0].sevenLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].sevenLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].sevenSize ? (
              <div className="h4">{serachDownladLink[0].sevenSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].sevenLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].sevenLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            <div className="EpisodeBtn">
              {serachDownladLink[0].sevenEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].sevenBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].sevenBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* eight */}
          <div className="linkBox">
            {serachDownladLink[0].eightLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].eightLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].eightSize ? (
              <div className="h4">{serachDownladLink[0].eightSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].eightLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].eightLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            <div className="EpisodeBtn">
              {serachDownladLink[0].eightEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].eightBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].eightBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* nine */}
          <div className="linkBox">
            {serachDownladLink[0].nineLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].nineLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].nineSize ? (
              <div className="h4">{serachDownladLink[0].nineSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].nineLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].nineLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            <div className="EpisodeBtn">
              {serachDownladLink[0].nineEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].nineBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].nineBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* ten */}
          <div className="linkBox">
            {serachDownladLink[0].tenLinkText ? (
              <>
                <hr width="100%" color="white" />
                <h1>{serachDownladLink[0].tenLinkText}</h1>
              </>
            ) : (
              ""
            )}
            {serachDownladLink[0].tenSize ? (
              <div className="h4">{serachDownladLink[0].tenSize}</div>
            ) : (
              ""
            )}
            {serachDownladLink[0].tenLink ? (
              <a
                className="btnMain"
                target="_blank"
                href={serachDownladLink[0].tenLink}
              >
                Download (G-drive)
              </a>
            ) : (
              ""
            )}

            <div className="EpisodeBtn">
              {serachDownladLink[0].tenEpisode1Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode1Link}
                >
                  Episode 1
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode2Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode2Link}
                >
                  Episode 2
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode3Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode3Link}
                >
                  Episode 3
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode4Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode4Link}
                >
                  Episode 4
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode5Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode5Link}
                >
                  Episode 5
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode6Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode6Link}
                >
                  Episode 6
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode7Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode7Link}
                >
                  Episode 7
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode8Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode8Link}
                >
                  Episode 8
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode9Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode9Link}
                >
                  Episode 9
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode10Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode10Link}
                >
                  Episode 10
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode11Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode11Link}
                >
                  Episode 11
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode12Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode12Link}
                >
                  Episode 12
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode13Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode13Link}
                >
                  Episode 13
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode14Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode14Link}
                >
                  Episode 14
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode15Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode15Link}
                >
                  Episode 15
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode16Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode16Link}
                >
                  Episode 16
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode17Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode17Link}
                >
                  Episode 17
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode18Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode18Link}
                >
                  Episode 18
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode19Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode19Link}
                >
                  Episode 19
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenEpisode20Link ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenEpisode20Link}
                >
                  Episode 20
                </a>
              ) : (
                ""
              )}
              {serachDownladLink[0].tenBatchZipLink ? (
                <a
                  className="button-42"
                  target="_blank"
                  href={serachDownladLink[0].tenBatchZipLink}
                >
                  Zip Download
                </a>
              ) : (
                ""
              )}
            </div>
          </div>


          {serachDownladLink[0].trailerLink ? (
            <iframe
              width="560"
              height="315"
              src={serachDownladLink[0].trailerLink}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          ) : (
            ""
          )}
          <div className="red text-shadow">HOW TO DOWNLOAD</div>
          {/* <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/VLgVw2NEqCM?si=t2IJIx7H35_yVJMo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
          <p className="overview">
            Here you can download 480p, 720p, 1080p x264 , 1080p 60FPS, 1080p
            x265 10Bit, 4k HDR, 4k 2160p SDR & 3D Movies through Google Drive
            Links. High-quality movies with the best quality options. We also
            focus on providing the best quality audio available. 4k HEVC Dolby
            Atmos is one of the best High-quality formats with low file sizes.
            We provide a fast & safe direct google drive link to download the
            best quality stuff from the best Encoders. You can easily clone our
            files into your G-Drive and make your own collection of high-quality
            movies. Google Drive Direct/Login to download/Make Clone option are
            the best way to download or make a copy in your google drive.
          </p>
          <p className="overview">
            <span>Note : </span> We Do not host any files on our server. All
            files shared here are collected from the internet from various
            Encoders and hosted on third-party sites. We do not accept
            responsibility for content hosted on third-party websites. We just
            index those links which are already available on the internet.
          </p>
        </>
      ) : (
        <>
          {movieDetail.original_title ? (
            <h1 className="headText">
              Download
              <span className="original_title">
                {" "}
                {movieDetail.original_title}{" "}
              </span>
              720p & 1080p & 2k Web-DL Dual Audio
            </h1>
          ) : (
            ""
          )}
          {movieDetail.backdrop_path ? (
            <img  onClick={()=>{
              navigator.clipboard.writeText(`https://image.tmdb.org/t/p/original${movieDetail.backdrop_path}`)
            }}
              src={`https://image.tmdb.org/t/p/original${movieDetail.backdrop_path}`}
              alt=""
            />
          ) : (
            ""
          )}
          {/* {movieDetail.overview ? <p>{movieDetail.overview}</p> : ""}
          <div className="genres">
            {movieDetail.genres
              ? movieDetail.genres.map((genre) => (
                <>
                  <span className="genre" id={genre.id}>
                    {genre.name}
                  </span>
                </>
              ))
              : ""}
          </div> */}

          <a
            className="btnMain"
            target="_blank"
            href="https://instantlinks.in/QMjFi"
          >
            Download (G-drive)
          </a>
          <p className="overview">
            Here you can download 480p, 720p, 1080p x264 , 1080p 60FPS, 1080p
            x265 10Bit, 4k HDR, 4k 2160p SDR & 3D Movies through Google Drive
            Links. High-quality movies with the best quality options. We also
            focus on providing the best quality audio available. 4k HEVC Dolby
            Atmos is one of the best High-quality formats with low file sizes.
            We provide a fast & safe direct google drive link to download the
            best quality stuff from the best Encoders. You can easily clone our
            files into your G-Drive and make your own collection of high-quality
            movies. Google Drive Direct/Login to download/Make Clone option are
            the best way to download or make a copy in your google drive.
          </p>
          <p className="overview">
            <span color="red">Note :</span> We Do not host any files on our
            server. All files shared here are collected from the internet from
            various Encoders and hosted on third-party sites. We do not accept
            responsibility for content hosted on third-party websites. We just
            index those links which are already available on the internet.
          </p>
        </>
      )}

      {/* <a target="_top" href={serachDownladLink[0].link} >go to</a> */}
      {
        // console.log(serachDownladLink[0].original_title)
      }
      <Link className="h4" to="/">
        Go Home <i class="fa-solid fa-arrow-right"></i>
      </Link>
    </DownloadMovieContainer>
  );
};

const DownloadMovieContainer = styled.div`
  width: 80%;
  height: auto;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  text-align: center;
  h1 {
    font-weight: 900;
    font-size: 25px;
  }
  p {
    width: 80%;
    font-size: 16px;
    font-weight: 500;
  }
  img {
    width: 80%;
    height: 80%;
  }

  .original_title {
    color: red;
  }
  iframe {
    margin-top: 20px;
  }
  span {
    color: red;
  }
  .headText {
    width: 80%;
  }
  .h4 {
    color: red;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
    letter-spacing: 2px;
  }
  .details-of-rating {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .genres {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
    .genre {
      margin-top: 10px;
      border: 2px solid white;
      padding: 7px 14px;
      border-radius: 10px;
      font-size: 14px;
      color: white;
    }
  }

  .imgbox {
    img {
      max-width: 800px;
    }
  }
  .linkBox {
    width: 80%;
    /* display: flex;-
    flex-direction: column;
    align-items: center; */
    flex-wrap: wrap;
    word-wrap: break-word !important;
  }

  .EpisodeBtn {
    margin: 0 auto;
    max-width: 300px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 1040px) {
    .linkBox {
      width: 100% !important;
      height: auto;
      padding: 0 10px !important;
      word-wrap: break-word !important;
      margin-bottom: 10px;
      overflow-wrap: break-word !important;
    }
  }

  .red {
    margin-top: 20px;
    font-size: 26px;
    color: #f44d14;
    font-weight: 900;
    border: 3px solid #f44d14;
    padding: 7px 14px;
    border-radius: 10px;
  }

  .text-shadow {
    font-style: italic;
    text-transform: uppercase;
    color: #fff;
    -webkit-text-stroke: #f44d14;
    -webkit-text-stroke-width: 1px;
    text-shadow: 2px 2px 10px #f44d14;
    transition: all 0.5s ease-in-out;
    text-align: center;
    letter-spacing: 0.2em;
    animation: flicker 0.5s ease-in-out infinite alternate;
  }
  @keyframes flicker {
    0% {
      opacity: 0.5;
      text-shadow: 2px 2px 10px #f44d14;
    }
    100% {
      opacity: 1;
      text-shadow: 2px 2px 20px #f44d14;
    }
  }
  @media only screen and (max-width: 425px) {
    .headText {
      width: 100% !important;
    }
    width: 90%;
    h1 {
      font-weight: 900;
      font-size: 18px;
      word-wrap: break-word !important;
    }
    p {
      width: 80%;
      font-size: 12px;
      font-weight: 500;
    }
    img {
      width: 100% !important;
      /* height: 90%; */
    }
    .overview {
      width: 100% !important;
      text-align: justify;
    }
    .linkBox {
      width: 100% !important;
      height: auto;
      word-wrap: break-word !important;
      margin-bottom: 10px;
    }
    iframe {
      width: 100% !important;
    }
  }
`;
export default DownloadMovie;
