import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MovieData } from "../data/MovieDownloadData";

const Card = ({ movie }) => {
  const [isLoading, setIsLoding] = useState(true);

   const [serachDownladLink, setSearchDownloadLink] =useState('')

  useEffect(() => {
    setTimeout(() => {
      setIsLoding(false);
    }, 1500);
  });
  return (
    <Cards>
      {isLoading ? (
        <div className="cards">
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton height={300} duration={2} />
          </SkeletonTheme>
        </div>
      ) : (
        <Link className="Link-Remove" target="_blank" to={`/movie/${movie.id}/download`}>
        
          <div className="cards ">
            <div className="cards-img">
            </div>
              <img
                src={movie.poster_path?`https://image.tmdb.org/t/p/w300/${movie.poster_path}`:'../img/no-poster.png'}
                alt="MoviezMarket"
              />
           
            <div className="cards-detail">
              <h2>{movie.title}</h2>
              <p>
                {movie.release_date}
                <span>
                  {movie.vote_average}
                  <i class="fa-solid fa-star"></i>
                </span>
              </p>
              <p className="overview">{movie.overview.slice(0, 100)}....</p>
            </div>
          </div>
        </Link>
      )}
    </Cards>
  );
};

const Cards = styled.div`

  .cards {
    overflow: hidden;
    height: 300px;
    border: 2px solid white;
    width: 200px;
    position: relative;
    transition: 0.7s;
  }
  .cards:hover {
    scale: 1.1;
    .cards-detail {
      opacity: 1;
    }
    .cards-img::before {
      opacity: 0.4;
    }
  }

  img {
    height: 300px;
    z-index: 0;
    opacity: 1;
  }
  .cards-img::before {
    opacity: 0;
    position: absolute;
    content: "";
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .cards-detail {
    opacity: 0;
    margin-left: 8px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 50%;
    bottom: 0;
    text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
    h1,h2 {
      font-size: 16px;
      text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
    }

    p {
      display: flex;
      gap: 10px;
      font-weight: 600;
      font-size: 12px;
      text-shadow: 3px 4px 7px rgba(81, 67, 21, 0.8);
    }
  }
  @media only screen and (max-width: 425px) {
    .cards {
      height: 200px;
      width: 130px;
    }
    img {
      height: 200px;
      /* width: 150px; */
    }
    .cards-detail {
      top: 70%;
      opacity: 1;
      margin-left: 8px;
    }
    .overview {
      opacity: 0 !important;
      position: absolute;
    }
    .cards-img::before {
      opacity: 0.25;
    }
    h1,h2{
      font-size: 12px !important;
    }
    p{
      font-size: 10px !important;
    }
  }
`;

export default Card;
