import styled from "styled-components";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <NavContainer>
      <div className="nav">
        <div className="logo">
          <Link to="/" className="Link-Remove">
            <h1>MoviezMarket</h1>
          </Link>
        </div>
        <div className="Nav-list">
          <div className="list">
            <Link className="link" to="/">
              Movie
            </Link>
            <Link className="link" to="/webseries">
              Web Series
            </Link>
            <Link className="link" to="/Bollywood/movies">
              BollyWood
            </Link>
          </div>
          <div className="search">
            <Link className="link" to="/movies/search ">
              <i class="fas fa-search"></i>
            </Link>
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.nav`

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 1440px;
  color: white;
  background-color: #1f2324;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  font-weight: 600;
  h1{
    margin: 0;
    padding: 0;
    font-weight: 900;
  }
  .nav {
    /* padding: 0 10%; */
    width: 100%;

    .logo {
      min-height: 60px;
      font-size: 30px;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #1f2324;
    }

    .Nav-list {
      max-width: 1440px;
      min-height: 60px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10%;
      gap: 20px;

      .list {
        margin-left: 20px;
        display: flex;
        gap: 30px;
        align-items: center;
        /* justify-content: space-around; */
        flex: 1;
        width: 100%;
        flex-wrap: nowrap;
        .link {
          all: unset;
          cursor: pointer;
          
        }
      }
      .search {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        width: 100%;
        i {
          font-size: 20px;
          color: white;
        }
      }
    }
    //resposive css Start
    @media only screen and (max-width: 425px) {
  
      
    .logo {
      min-height: 50px;
      font-size: 25px;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      .Link-Remove{
      color: #1f2324 !important;
    }
    }
  
      .Nav-list{
        display: flex;
        align-items: center !important;
        justify-content: center  !important;
      }
      .list{
        justify-content: center;
        
      }
      .list .link {
          font-size: 14px !important;
          font-weight: 500;
          white-space: nowrap !important;
          inline-size: min-content !important;
        }
      .search {
        display: flex;
        justify-content: center !important;
      }
    }
  }
`;

export default Navbar;
